.landing-banner {
  color: var(--color-foreground-on-accent);
  font-family: "Market Sans", Arial, sans-serif;
  height: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-banner--orange {
  background-color: var(--color-marketing-orange-background-3);
  color: var(--color-marketing-orange-foreground-3);
}

.landing-banner--magenta {
  background-color: var(--color-marketing-magenta-background-4);
  color: var(--color-marketing-magenta-foreground-4);
}

.landing-banner--green {
  background-color: var(--color-marketing-green-background-3);
  color: var(--color-marketing-green-foreground-3);
}

.HomePage__SpacingTop {
  padding-top: 80px;
}

@media screen and (min-width: 768px) {
  .landing-banner {
    height: 330px;
  }
}
